<template>
  <BCard>
    <div class="font-bold m-0 text-black text-2xl">
      Riwayat Pengajuan
    </div>
    <BRow class="mt-1 justify-end mr-0">
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'center'"
        class="withdrawal-date-range"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                "
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last30)
                "
                style="color: #828282 !important"
              >
                30 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else
                style="color: #828282 !important"
              > Custom </span>
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
              alt="Komerce"
            >
          </div>
        </template>
      </DateRangePicker>
      <BFormSelect
        v-model="categoryFilter"
        placeholder="Pilih Status"
        :options="categoryOptions"
        class="ml-1"
        style="width: 15%"
      />
      <BFormSelect
        v-model="statusFilter"
        placeholder="Pilih Status"
        :options="statusOptions"
        class="ml-1"
        style="width: 15%"
      />
      <img
        v-b-tooltip.hover.top="'Reset Filter'"
        class="ml-[10px] cursor-pointer"
        src="https://storage.googleapis.com/komerce/assets/icons/refresh-2.svg"
        alt="Komerce"
        @click="handleResetFilter"
      >
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-1"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(submission_type)="data">
          <div style="min-width: 150px">
            {{ data.item.submission_type }}
          </div>
        </template>
        <template #cell(submission_date)="data">
          <div style="min-width: 150px">
            {{ DAY_MONTH_YEAR(data.item.submission_date) }}
            <br>
            <span style="color: #828282">{{ TIME(data.item.submission_date) }}</span>
          </div>
        </template>
        <template #cell(warehouse_logo_url)="data">
          <div class="flex items-center">
            <BAvatar :src="data.item.warehouse_logo_url" />
            <div class="ml-1">
              <h5 class="text-black m-0">
                {{ data.item.warehouse_name }}
              </h5>
              <span class="text-gray-500">{{ data.item.warehouse_city }}</span>
            </div>
          </div>
        </template>
        <template #cell(partner_logo_url)="data">
          <div class="flex items-center">
            <BAvatar :src="data.item.partner_logo_url" />
            <div class="ml-1">
              <h5 class="text-black m-0">
                {{ data.item.partner_name }}
              </h5>
              <span class="text-gray-500">{{ data.item.partner_city }}</span>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <BRow
            :class="classWarehouse(data.item.status)"
            style="min-width: 150px"
          >
            {{ data.item.status }}
          </BRow>
        </template>
        <template #cell(detail)="data">
          <span
            style="color: #f95031"
            class="cursor-pointer"
            @click="handleClick(data.item)"
          >
            Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BCard, BTable, BFormSelect, BRow,
} from 'bootstrap-vue'
import { axiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  komshipDate,
  last7,
  last30,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, DAY_MONTH_YEAR, TIME,
} from '@/libs/filterDate'
import { fieldList, statusOptions, categoryOptions } from './config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    DateRangePicker,
    BRow,
  },
  data() {
    return {
      items: [],
      loading: true,
      fields: fieldList,
      statusFilter: null,
      categoryFilter: null,
      statusOptions,
      categoryOptions,
      dateRange: {
        startDate: komshipDate,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Custom ': [komshipDate, today],
      },
      today,
      last7,
      last30,
      lastDateOfMonth,
      komshipDate,
      firstDateOfMonth,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      TIME,
      resetFilter: true,
    }
  },
  watch: {
    categoryFilter: {
      handler() {
        this.getData()
      },
    },
    statusFilter: {
      handler() {
        this.getData()
      },
    },
    dateRange: {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(value) {
      this.loading = true
      const url = '/v1/warehouse/submission'
      const params = {
        submission_type: !value ? this.categoryFilter : '',
        submission_status: !value ? this.statusFilter : '',
        start_date: !value ? YEAR_MONTH_DAY(this.dateRange.startDate) : '',
        end_date: !value ? YEAR_MONTH_DAY(this.dateRange.endDate) : '',
      }
      await axiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    classWarehouse(status) {
      if (status === 'Sedang Diajukan') {
        return 'isBeingSubmitted'
      }
      if (status === 'Disetujui') {
        return 'approve'
      }
      if (status === 'Ditolak') {
        return 'reject'
      }
      return ''
    },
    handleClick(data) {
      const { id } = data
      this.$router.push({
        path: `/history-submission/${id}`,
      })
    },
    handleResetFilter() {
      this.getData(this.resetFilter)
      this.categoryFilter = null
      this.statusFilter = null
      this.dateRange.startDate = null
      this.dateRange.endDate = null
    },
  },
}
</script>
<style scoped>
.isBeingSubmitted {
  background-color: #fff2e2;
  color: #fba63c;
  border-radius: 20px;
  padding: 0 10px;
  justify-content: center;
}
.approve {
  background-color: #dcf3eb;
  color: #34a770;
  border-radius: 20px;
  padding: 0 10px;
  justify-content: center;
}
.reject {
  background-color: #ffeded;
  color: #e31a1a;
  border-radius: 20px;
  padding: 0 10px;
  justify-content: center;
}
</style>
